import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { Layout, HeaderTwo, FooterOne } from "gatsby-theme-layout";
import {
    FeatureSection02,
    FeatureSection03,
    FunFactArea03,
    HeroSection02,
    PricingPlanArea02,
    VideoStreamingContainerFour,
    FAQ01,
} from "gatsby-theme-container";
import { normalizedData } from "@streamo/helper/methods";

const StreamingIndex = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Landing Page" />
            <HeaderTwo data={{ ...data?.header, ...data.navigation }} />
            <HeroSection02 data={content?.["hero-section"]} />
            <FeatureSection02 data={content?.["feature-section-01"]} />
            <FunFactArea03 data={content?.["funfact-section"]} />
            <FeatureSection03 data={content?.["feature-section-02"]} />
            <PricingPlanArea02 data={content?.["pricing-plan-section"]} />
            <FeatureSection02 data={content?.["feature-section-03"]} />
            <VideoStreamingContainerFour
                data={{
                    ...content?.["latest-movies-section"],
                    items: data?.allLatestMoviesPost?.nodes,
                }}
            />
            <FAQ01 data={content?.["faq-section"]} />
            <FooterOne data={{ ...data?.footer, ...data?.contact }} />
        </Layout>
    );
};

export const query = graphql`
    query StreamingLandingPageQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-streaming" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "landing-page" }) {
            content {
                ...PageContent
            }
        }
        allLatestMoviesPost: allVideoStreamingMovie(
            sort: { fields: date, order: DESC }
            filter: { comingSoon: { eq: false } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
    }
`;

StreamingIndex.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        featuredMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allLatestMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allVideoStreamingHorrorSeries: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allOldMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allVideoStreamingFamilyMovie: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        horrorSeriesBanner: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        awardWinningMovie: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default StreamingIndex;
